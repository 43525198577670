import { ModuleWithProviders, NgModule, Optional, SkipSelf, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf, Observable } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { DataModule } from './data/data.module';
import { AnalyticsService } from './utils/analytics.service';
import { map } from 'rxjs/operators';

/*
const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'socicon-github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'socicon-facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'socicon-twitter',
  },
];*/
@Injectable()
export class NbSimpleRoleProvider extends NbRoleProvider {
  
  constructor(private authService: NbAuthService) {
    super();
  }

  getRole(): Observable<string> {
    return this.authService.onTokenChange()
      .pipe(
        map((token: NbAuthJWTToken) => {
          // if (token.getPayload().user.Role){
          //   this.role = token.getPayload().user.Role.Name;
          // }
          let ret = 'guest'
          if (token.isValid()){
            if (token.getPayload().user.Role){
              ret = token.getPayload().user.Role.Name;
            }
          }
          return ret;
        }),
      );
  }
  
  
  // getRole() {
  //   // here you could provide any role based on any auth flow
  //   return observableOf('guest');
  // }
}

export const NB_CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        token: {
          class: NbAuthJWTToken,

          key: 'token', // this parameter tells where to look for the token
        },
        baseEndpoint: 'https://freeandvictorious.azurewebsites.net/api',// 'https://api.useredystaf.com',
        login: {
          endpoint: '/Account/LoginJWT',
          method: 'post',
          
        },
        // register: {
        //   endpoint: '/custom/register',
        //   method: 'post',
        // },
        logout: {
          endpoint: '/Account/Logout',
          method: 'get',
          redirect: {
            success: 'auth/login',
            failure: 'auth/login',
          }
        },
        requestPass: {
          endpoint: '/Account/ForgotPass',
          method: 'post',
          redirect: {
            success: null,
            failure: null,
          },
          defaultErrors: ['Invalid Account.  Please check your email.'],
        },
        resetPass: {
          endpoint: '/Account/UpdatePass',
          method: 'post',
          redirect: {
            success: null,
            failure: 'auth/request-password',
          },
          defaultErrors: ['Invalid Link.  Please try again.'],
          defaultMessages: ['Your password has been successfully changed.  You may now log into the app with your new password.'],
        },
      }),
    ],
    forms: {
      login: {
        // socialLinks: socialLinks,
       },
       register: {
       //  socialLinks: socialLinks,
       },
       resetPassword: {
         redirectDelay: 90000,
              showMessages: {
                success: true,
              },
       }
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      User: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
      SuperAdmin: {
        parent: 'User',
        create: '*',
        edit: '*',
        remove: '*',
        super: '*'
      }
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
